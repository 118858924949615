import http from '../http'

export default {
  getRecords (payload) {
    return http.get(`/campaign?per_page=${payload.perPage}&page=${payload.page}&search=${payload.search}`)
  },
  createRecord (role) {
    return http.post('/campaign', role)
  }
}
