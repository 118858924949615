import service from '../../api/campaign'
const state = {
}

const mutations = {
}

const actions = {
  getRecords ({ commit }, payload) {
    return new Promise((resolve, reject) => {
      service.getRecords(payload).then((res) => {
        resolve(res)
      }).catch(error => {
        reject(error)
      })
    })
  },
  createRecord ({ commit }, record) {
    return new Promise((resolve, reject) => {
      service.createRecord(record).then((res) => {
        resolve(res)
      }).catch(error => {
        reject(error)
      })
    })
  }
}
const getters = {}

export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters
}
