import http from '../http'

export default {
  getRecords (payload) {
    return http.get(`/company?per_page=${payload.perPage}&page=${payload.page}&search=${payload.search}`)
  },
  createRecord (record) {
    return http.post('/company', record)
  },
  updateRecord (record) {
    return http.put('/company', record)
  },
  getAllRecords () {
    return http.get('/company/all')
  }
}
