import service from '../../api/company'
const state = {
  companies: []
}

const mutations = {
  setCompanies (state, companies) {
    state.companies = companies
  }
}

const actions = {
  getRecords ({ commit }, payload) {
    return new Promise((resolve, reject) => {
      service.getRecords(payload).then((res) => {
        resolve(res)
      }).catch(error => {
        reject(error)
      })
    })
  },
  createRecord ({ commit }, record) {
    return new Promise((resolve, reject) => {
      service.createRecord(record).then((res) => {
        resolve(res)
      }).catch(error => {
        reject(error)
      })
    })
  },
  updateRecord ({ commit }, record) {
    return new Promise((resolve, reject) => {
      service.updateRecord(record).then((res) => {
        resolve(res)
      }).catch(error => {
        reject(error)
      })
    })
  },
  getAllRecords ({ commit }) {
    service.getAllRecords().then(response => {
      commit('setCompanies', response.data)
    }).catch(error => {
      console.log(error)
    })
  }
}
const getters = {}

export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters
}
